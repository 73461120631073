import get from 'lodash/get'
import camelCase from 'lodash/camelCase'

export const state = () => ({
  globalConfig: {},
  isDesktop: true,
  isTablet: true,
  language: null,
  userOriginCountry: null,
  countriesData: {},
  isAppsflyerDisabled: false
})

export const mutations = {
  setGlobalConfig(state, globalConfig) {
    state.globalConfig = globalConfig
  },

  setIsDesktop(state, isDesktop) {
    state.isDesktop = isDesktop
  },

  setIsTablet(state, isTablet) {
    state.isTablet = isTablet
  },

  setLanguage(state, language) {
    state.language = language
  },

  setUserOriginCountry(state, countryCode) {
    state.userOriginCountry = countryCode
  },

  setCountriesData(state, countriesData) {
    state.countriesData = countriesData
  },

  setIsAppsflyerDisabled(state, isAppsflyerDisabled) {
    state.isAppsflyerDisabled = isAppsflyerDisabled
  }
}

export const getters = {
  currency(state) {
    return state?.globalConfig?.currency || { symbol: '$', step: 10 }
  },

  customerCareLinks(state) {
    let customerCareLinks = kebabToCamel(
      get(state, 'globalConfig.customer-care.links', [])
    )
    customerCareLinks = Object.values(customerCareLinks).map((link) =>
      mapAttribute(link, state.language)
    )
    return customerCareLinks
  },

  customerService(state) {
    return kebabToCamel(get(state, 'globalConfig.customer-service', {}))
  },

  getAppDesktopDropdown(state) {
    return mapAttribute(
      get(state, 'globalConfig.get-app-desktop-dropdown'),
      state.language
    )
  },

  globalConfig(state) {
    return state.globalConfig
  },

  googleOneTapClientId(state) {
    return get(state, 'globalConfig.google.one-tap-client-id')
  },

  language(state) {
    return state.language
  },

  olapicConfig(state) {
    return kebabToCamel(state.globalConfig.olapic || {})
  },

  organization(state) {
    return kebabToCamel(state.globalConfig.organization || {})
  },

  promotions(state) {
    const platform = state.isDesktop || state.isTablet ? 'web' : 'mobile-web'
    const promotionsForPlatform = get(
      state,
      `globalConfig.promotions[${platform}]`
    )

    return (promotionsForPlatform || []).map((promotion) =>
      mapAttribute(promotion, state.language)
    )
  },

  paymentOptions(state) {
    return get(state, 'globalConfig.payment-options')
  },

  salePageVisibility(state) {
    return get(state, 'globalConfig.sale-page-visibility')
  },

  seoMeta(state) {
    const seoMeta = get(state, 'globalConfig.seo-meta', {})
    return mapAttribute(seoMeta, state.language)
  },

  social(state) {
    return kebabToCamel(state.globalConfig.social || {})
  },

  showGetAppSection(state) {
    return get(state, 'globalConfig.show-get-app-section')
  },

  showVariantTagsOnPlp(state) {
    return get(state, 'globalConfig.show-variant-tags-on-plp')
  },

  hasStoreLocations(state) {
    return get(state, 'globalConfig.store-locations')
  },

  supportGiftCard(state) {
    return get(state, 'globalConfig.support-gift-card')
  },

  hasOnlineReservationSystem(state) {
    return get(state, 'globalConfig.support-online-reservation-system')
  },

  website(state) {
    return kebabToCamel(state.globalConfig.website || {})
  },

  aboutUsTitle(state) {
    return get(state, `globalConfig.about-us.title[${state.language}]`, '')
  },

  aboutUsLinks(state) {
    const links = get(state, 'globalConfig.about-us.links', [])
    return links.map((link) => mapAttribute(link, state.language))
  },

  brazeConfig(state) {
    return kebabToCamel(get(state, 'globalConfig.braze', {}))
  },

  zendeskWidgetKey(state) {
    return get(state, 'globalConfig.zendesk.widget-key')
  },

  signInBannerTexts(state) {
    return get(state, 'globalConfig.sign-in-banner') || {}
  },

  getUserOriginCountry(state) {
    return state.userOriginCountry
  },

  getCountriesData(state) {
    return state.countriesData
  },

  appsflyerWebKey(state) {
    return get(state, 'globalConfig.appsflyer.web-key') || ''
  },

  enableOktaLogin(state) {
    return get(state, 'globalConfig.enable-okta-login')
  },

  legalNotice(state) {
    return get(state, `globalConfig.legal-notice[${state.language}]`, '')
  },

  enableFullsize(state) {
    return get(state, 'globalConfig.enable-fullsize')
  },

  enableBeautyProfile(state) {
    return get(state, 'globalConfig.enable-beauty-profile')
  },

  fuelStranglerConfig(state) {
    return get(state, 'globalConfig.fuel-strangler-config')
  }
}

function mapAttribute(hash, language) {
  const result = {}
  // Accept a hash of attributes and map it based on the language.
  // Eg. { text: { en: 'Lorem Ipsum'} } to { text: 'Lorem Ipsum' }
  // For the attribute that doesn't have language key, it will return the original value
  if (!hash) {
    return {}
  }
  Object.keys(hash).map(
    function (key, index) {
      if (typeof hash[key] === 'object') {
        result[key] = hash[key][this.language] ? hash[key][this.language] : ''
      } else {
        result[key] = hash[key]
      }
    },
    { language }
  )

  return kebabToCamel(result)
}

function kebabToCamel(object) {
  // Accept an object an convert all the key of object into camelCase recursively
  // Eg. { 'en-SG': { 'preferred-language': 'en' }} to { enSg: { preferredLanguage: 'en' }}
  if (Array.isArray(object)) {
    return object.map((value) => kebabToCamel(value))
  } else if (object !== null && typeof object === 'object') {
    return Object.keys(object).reduce(function (result, key) {
      return {
        ...result,
        [camelCase(key)]: kebabToCamel(object[key])
      }
    }, {})
  }
  return object
}

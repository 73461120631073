import consola from 'consola'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import {
  MOBILE_WEB,
  WEB,
  WEB_DESKTOP,
  WEB_MOBILE,
  WEB_TABLET
} from '@sephora-asia/common-ui/config/platforms.js'
import createRepository from '~/api/repository'
import {
  REFRESH_TOKEN_PATH,
  fetchRefreshTokenWithAppFlow,
  fetchRefreshTokenWithClientFlow
} from '~/api/refresh-token.js'
import AuthManager from '~/modules/auth/AuthManager.js'

const logger = consola.withTag('axios')
export const SOCIAL_LOGIN_PATH = '/api/v2.5/auth/social/login'
const noAuthRefreshRoutes = [SOCIAL_LOGIN_PATH]

function getAppPlatform(device) {
  if (device.isMobile) {
    return WEB_MOBILE
  } else if (device.isTablet) {
    return WEB_TABLET
  } else {
    return WEB_DESKTOP
  }
}

export default ({ app, $axios, store }, inject) => {
  // On server side it would be default host and port(localhost:3000) to round trip through the load balancer
  if (process.client) {
    $axios.setBaseURL('/')
  }

  $axios.defaults.headers.common = $axios.defaults.headers.common || {}
  $axios.defaults.headers.common.Accept = 'application/vnd.api+json'
  $axios.defaults.headers.common['Accept-Language'] = app.i18n.acceptLanguage
  $axios.defaults.headers.common['Content-Type'] = 'application/json'
  $axios.defaults.headers.common['X-Platform'] = app.$device.isMobile
    ? MOBILE_WEB
    : WEB

  // V2.5 specific headers
  $axios.defaults.headers.common['X-App-Platform'] = getAppPlatform(app.$device)
  $axios.defaults.headers.common['X-App-Version'] = '1.0.0'
  $axios.defaults.headers.common['X-Site-Country'] = app.i18n.countryCode

  // Timeout requests after 15 seconds. Value chosen based on data from Web UI New Relic
  $axios.defaults.timeout = 15000 // in ms

  const authManager = new AuthManager(app, store)

  function fetchRefreshToken() {
    if (process.server) {
      // { fromRes: true } option makes $cookies return data from axios response object
      // which in this case will return the newly set (refreshed) JWT info from the cookies
      const jwtInfo =
        authManager.jwtInfo({ fromRes: true }) || authManager.jwtInfo()

      return fetchRefreshTokenWithAppFlow($axios, jwtInfo)
        .then(({ data }) => {
          logger.success('JWT refreshed successfully')
          authManager.setJWTCookies(data)
        })
        .catch((e) => {
          logger.info('Interceptor - Server - JWT refresh failed')
          logger.info(e)
        })
    } else {
      return fetchRefreshTokenWithClientFlow($axios).catch((e) => {
        logger.info('Interceptor - Client - JWT refresh failed')
        logger.info(e)
      })
    }
  }

  // The refreshed JWT cookies set in L61 do not get sent along with the axios API requests (regular/stalled)
  // Hence follow the request interception method to ensure API requests always have the latest JWT token
  // by setting the token as Authorization header
  // Ref: https://github.com/Flyrell/axios-auth-refresh#request-interceptor
  function setAuthorizationHeader(request) {
    const token =
      authManager.jwtInfo({ fromRes: true }).token ||
      authManager.jwtInfo().token

    // undefined Authorization header causes the request to throw error
    if (token) {
      request.headers.Authorization = token
    }
  }

  // All API calls from Web UI hit the the luxola ALB directly. This was implemented during Black Friday
  // prep of 2021 to fix latency issues (slow page loads) caused by using Sephora public URL which added
  // an additional hop to Akamai.
  //
  // Since the current strangulation setup is done in Akamai, Web UI is required to hit Sephora public URL for
  // the APIs that are part of the release in order to follow the strangulation rules and split the traffic.
  //
  // This function changes the base URL for BFF requests based on the fuel strangler config
  function changeBaseUrlForBFFRequest(request) {
    const [, apiVersion, apiEntity] =
      request.url.match(/\/api\/(v\d\.\d)\/(\w*)(.*)/) || []

    if (!apiVersion || !apiEntity) return

    const fuelStranglerConfig =
      store.getters['globalConfig/fuelStranglerConfig']?.[apiEntity]

    if (fuelStranglerConfig?.version) {
      request.url = request.url.replace(apiVersion, fuelStranglerConfig.version)
    }

    if (!process.client && fuelStranglerConfig?.['base-url']) {
      request.baseURL = fuelStranglerConfig['base-url']
    }
  }

  $axios.interceptors.request.use((request) => {
    setAuthorizationHeader(request)
    changeBaseUrlForBFFRequest(request)

    return request
  })

  // This function intercepts requests which return 401 (Unauthorized), refreshes the authorization
  // via error callback and continues with the original request. It also stalls additional requests
  // that have come in while waiting for a new JWT and resolves them when a new JWT is available.
  //
  // NOTE: This needs to be placed before the default interceptor as it only intercepts 401 (Unauthorized)
  createAuthRefreshInterceptor($axios, async (error) => {
    logger.error(`Interceptor - ${error} for path ${error.response.config.url}`)
    const errorResponse = error?.response?.data?.errors?.[0] || {}
    const errorMeta = error?.response?.data?.meta || {}

    // We do not need to refresh JWT for social login auth
    if (
      noAuthRefreshRoutes.includes(error?.response?.config?.url) &&
      errorMeta.error_type === 'blocked'
    )
      return authManager.handleAuthBlocked(errorResponse, errorMeta)

    if (
      authManager.jwtInfo().signingMethod === 'asymmetric' ||
      error?.response?.config?.url !== REFRESH_TOKEN_PATH
    )
      await fetchRefreshToken()
  })

  $axios.interceptors.response.use(
    (response) => {
      if (process.server) {
        logger.info(
          (({ status, statusText, config, headers }) =>
            JSON.stringify({
              request: config,
              response: {
                status,
                statusText,
                headers
              }
            }))(response)
        )
      }
      return response
    },
    (error) => {
      if (error.response.status === 401) return

      logger.error(`${error} for path ${error.response.config.url}`)

      // wrapping error in a object to prevent maximum call stack exceed error
      // assigning error to a variable as linting raising "Expected the Promise rejection reason to be an Error"
      const errorObject = { error }
      return Promise.reject(errorObject)
    }
  )

  const repositoryWithAxios = createRepository($axios)
  const repositories = {
    search: repositoryWithAxios('/api/v3/search'),
    billboards: repositoryWithAxios('/api/v2.4/billboards'),
    bookingServiceCategories: repositoryWithAxios(
      '/api/booking-services/api/v1/activity_groups'
    ),
    bookingServicePages: repositoryWithAxios(
      '/api/booking-services/api/v1/pages'
    ),
    bookingServiceBeautyClasses: repositoryWithAxios(
      '/api/booking-services/api/v1/activities?activity_type=beauty%20class'
    ),
    brands: repositoryWithAxios('/api/v2.5/brands'),
    bag: repositoryWithAxios('/api/v2.5/carts'),
    brandRecommendations: repositoryWithAxios(
      '/api/v2.5/product_recommendations/products_by_popularity'
    ),
    categories: repositoryWithAxios('/api/v2.5/categories'),
    homePageCarousels: repositoryWithAxios('/api/v2.4/home_page_carousels'),
    featureCarousel: repositoryWithAxios('/api/v2.4/feature_carousels/live'),
    layoutConfig: repositoryWithAxios('/api/v2.4/layout_config'),
    lineItems: repositoryWithAxios('/api/v2.5/cart/line_items'),
    popularQuerySearch: repositoryWithAxios('/api/v2.3/search/popular_queries'),
    productFilters: repositoryWithAxios('/api/v2.3/products/filters'),
    products: repositoryWithAxios('/api/v2.3/products'),
    quickLinks: repositoryWithAxios('/api/v2.4/homepage_quick_links'),
    sessions: repositoryWithAxios('/api/v2.5/sessions'),
    shopByCampaigns: repositoryWithAxios('/api/v2.4/shop_by_campaigns/latest'),
    socialAuth: repositoryWithAxios(SOCIAL_LOGIN_PATH),
    users: repositoryWithAxios('/api/v2.4/account/user'),
    waitlists: repositoryWithAxios('/api/v2.3/my/waitlist/variants'),
    waitlistVariants: repositoryWithAxios('/api/v2.3/waitlist/variants'),
    wishlists: repositoryWithAxios('/api/v2.5/wishlist'),
    wishlistVariants: repositoryWithAxios('/api/v2.3/wishlist/variants'),
    productRecommendations: repositoryWithAxios(
      '/api/v2.5/product_recommendations/products_by_strategy'
    ),
    overlay: repositoryWithAxios('/api/v2.5/overlays/latest')
  }
  inject('repositories', repositories)
}

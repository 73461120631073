import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      bookingServiceCategories: 'bookingService/categories',
      bookingServicePages: 'bookingService/pages',
      bookingServiceBeautyClasses: 'bookingService/beautyClasses',
      isBookingServiceLoaded: 'bookingService/isBookingServiceLoaded'
    }),

    beautyServicesLinks() {
      const links = [
        {
          label: this.$t('layout.shared.allBeautyServices'),
          path: '/beauty-services'
        }
      ]
      if (this.bookingServiceCategories) {
        const filteredCategories = this.bookingServiceCategories.filter(
          (category) =>
            !(
              (category.attributes['booking-vendor'] === 'In House' &&
                category.attributes['cutover-from'] === null &&
                category.attributes['cutover-till'] === null) ||
              category.attributes['activity-type'] !== 'Service'
            ) && category.attributes.status === 'published'
        )

        filteredCategories.map((category) => {
          links.push({
            label: category.attributes.name,
            path: `/beauty-services/${category.attributes['slug-url']}`
          })
        })
      }

      return links
    },

    storeEventsLink() {
      return {
        label: this.$t('layout.shared.events'),
        description: this.$t('layout.bookingService.eventDescription'),
        path: '/store-events'
      }
    },

    storeLocationsLink() {
      return {
        label: this.$t('layout.shared.stores'),
        description: this.$t('layout.bookingService.storeDescription'),
        path: '/store-locations'
      }
    },

    storesAndEventsLinks() {
      const links = [this.storeEventsLink, this.storeLocationsLink]

      if (this.bookingServiceBeautyClasses.length !== 0) {
        links.push({
          label: this.$t('layout.shared.beautyClasses'),
          description: this.$t('layout.bookingService.classesDescription'),
          path: '/beauty-classes'
        })
      }

      if (this.bookingServicePages) {
        this.bookingServicePages.map((page) => {
          links.push({
            label: page.attributes['page-title'],
            description: page.attributes['dropdown-description'],
            path: `/stores-and-events/${page.attributes['slug-url']}`
          })
        })
      }

      return links
    }
  }
}
